import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  AdditionalParamsData,
  ConfigurationInfo,
} from "./configurator.service";
import { CloudConfig, SystemService } from "./system.service";

export enum CloudApiMode {
  Production,
  Development,
}

export interface CloudEvent {
  response?: OrderEstimateWithSettings;
  isEstimateComplete?: boolean;
  errors?: string;
}

export enum EstimateMode {
  FullOrder,
  EachProduct,
  EachElement,
  EachProductAndElement,
}

export enum RoundingMode {
  TheNearestWhole,
  AfterDecimalPoint,
  BeforeDecimalPoint,
}

interface BaseEstimateData {
  name?: string;
  price: number;
}

export type ElementEstimateData = BaseEstimateData;

export interface ProductEstimateData extends BaseEstimateData {
  additionalParams: AdditionalParamsData;
  elements: ElementEstimateData[];
}

export interface OrderEstimateData extends BaseEstimateData {
  products: ProductEstimateData[];
}

export interface OrderEstimateSettings {
  roundingPrice: boolean;
  numberOfSimbolsAfterComma: number;
  numberOfSimbolsBeforeComma?: number;
  roundingOfThePrice: RoundingMode;
}

export interface OrderEstimateWithSettings {
  estimate: OrderEstimateData;
  settings: OrderEstimateSettings;
}

export interface OrderProductData {
  id: number;
  name: string;
  article: string;
  additionalParams: AdditionalParamsData;
}

@Injectable({
  providedIn: "root",
})
export class CloudService {
  entityMap: { [item: string]: number } = {};
  private config: CloudConfig;

  constructor(private http: HttpClient, private system: SystemService) {
    this.system.getConfig(CloudConfig).subscribe((value) => {
      this.config = value;
    });
  }

  get cloudApiKey() {
    return this.config.cloudApiMode === CloudApiMode.Production
      ? this.config.cloudApiKey
      : this.config.cloudApiKeyDev;
  }

  async createCloudOrder(projectId) {
    const response = await this.http
      .post<any>(`/api/cloud/create-order/${projectId}`, null)
      .toPromise();

    this.clearEntityMap();
    return response;
  }

  async checkOrderStatus(orderId: number) {
    return await this.http
      .get<boolean>(`/api/cloud/check-order/${orderId}`)
      .toPromise();
  }

  async RunOrderCutting(orderId: number) {
    return await this.http
      .post<any>(`/api/cloud/run-cutting/${orderId}`, null)
      .toPromise();
  }

  async CalcEstimateWithHook(
    orderId: number,
    groupId: string,
    modelIds: number[]
  ) {
    const params = new HttpParams().set("groupId", groupId);

    return await this.http
      .post<any>(`/api/cloud/calc-estimate-with-hook/${orderId}`, modelIds, {
        params,
      })
      .toPromise();
  }

  async addElementToOrder(
    orderId: number,
    config: ConfigurationInfo,
    count: number
  ) {
    const params = new HttpParams()
      .set("count", count)
      .set("fromPlacement", true);

    const { modelId } = await this.http
      .post<any>(`/api/cloud/add-model-from-configurator/${orderId}`, config, {
        params,
      })
      .toPromise();

    this.entityMap[config.additionalParams.plannerUid] = modelId;
    return modelId;
  }

  async buildOrder(orderId: number, placementConfig) {
    const { taskId } = await this.http
      .post<any>(`/api/cloud/build-placement-order/${orderId}`, placementConfig)
      .toPromise();

    return taskId;
  }

  async deleteProducts(modelIds: number[]) {
    return await this.http
      .post<any>(`/api/cloud/delete-products`, modelIds)
      .toPromise();
  }

  async updateOrderSettings(orderId: number) {
    const { estimateChangedMode } = await this.http
      .post<any>(`/api/cloud/update-order-settings/${orderId}`, null)
      .toPromise();

    return estimateChangedMode;
  }

  async getOrderEstimate(orderId: number) {
    return await this.http
      .get<OrderEstimateWithSettings>(`/api/cloud/order-estimate/${orderId}`)
      .toPromise();
  }

  private clearEntityMap() {
    for (let item in this.entityMap) {
      delete this.entityMap[item];
    }
  }

  async fillEntityMapFromOrder(orderId: number) {
    const products = await this.getOrderProducts(orderId);

    this.clearEntityMap();
    products.forEach((item) => {
      this.entityMap[item.additionalParams.plannerUid] = item.id;
    });
  }

  async getOrderProducts(orderId: number) {
    return await this.http
      .get<OrderProductData[]>(`/api/cloud/order-products/${orderId}`)
      .toPromise();
  }

  async checkOrderProducts(orderId: number) {
    return await this.http
      .get(`/api/cloud/check-order-products/${orderId}`)
      .toPromise();
  }

  getSalonsList() {
    return this.http.get(`/api/cloud/salons-list`);
  }

  getSalonInfo(id: number) {
    return this.http.get<any>(`/api/cloud/salon-info/${id}`);
  }

  getManagersList(id: number) {
    return this.http.get(`/api/cloud/salon-managers/${id}`);
  }

  getManagerInfo(id: number) {
    return this.http.get<any>(`/api/cloud/manager-info/${id}`);
  }
}
